import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;
  z-index: ${(props) => (props.zIndex ? props.zIndex : '4000')};
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: ${(props) => (props.width ? props.width + 'px' : '415px')};
  height: ${(props) => (props.height ? props.height + 'px' : '100%')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  padding: 35px;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;

  align-items: center;
  height: 20%;
  justify-content: ${(props) =>
    props.alignIcon ? props.alignIcon : 'flex-start'};
  width: ${(props) => (props.width ? props.width + '%' : '100%')};
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-left: 1.5em;
  border-radius: 4px;
  width: ${(props) => (props.bubbleWidth ? props.bubbleWidth : 'fit-content')};
  min-height: 35px;
  position: relative;
  padding: 0px 20px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-top: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 21px;
    transform: rotate(-135deg);
  }
`;

export const IconPersonStyled = styled.img`
  background-color: #ffff;
  border: 2px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  width: ${(props) => (props.sizeIconPerson ? props.sizeIconPerson : '95px')};
  height: ${(props) => (props.sizeIconPerson ? props.sizeIconPerson : '95px')};
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  font-weight: ${(props) =>
    props.bubbleFontWeight ? props.bubbleFontWeight : 'bold'};
  color: #ffff;
  margin: ${(props) => (props.bubbleMargin ? props.bubbleMargin : '13px 0')};
`;

export const ParagraphStyled = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '30px')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '312px')};
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gapParagraph ? props.gapParagraph + 'px' : '0px')};
  color: ${(props) =>
    props.colorParagraph ? props.colorParagraph : '#606062'};

  > p {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'start')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
    line-height: ${(props) =>
      props.lineHeight ? props.lineHeight : props.fontSize};
  }
  word-break: break-word;
`;

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width + 'px' : '290px')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border: none;
  border-radius: 4px;
  color: #ffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-weight: 600;
  font-size: 15px;

  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 375px) {
    width: 100%;
    order: 1;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: ${(props) =>
    props.marginButtons ? props.marginButtons : '30px'};
  width: 100%;

  flex-direction: ${(props) =>
    props?.config?.buttons?.flexDirection
      ? props?.config?.buttons?.flexDirection
      : props.isOtherExternal
      ? 'column-reverse'
      : 'unset'};

  @media (max-width: 375px) {
    flex-direction: column;
  }
`;

export const ButtonCancel = styled.button`
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  width: ${(props) => (props.width ? props.width + 'px' : '100px')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: transparent;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;

  font-size: 15px;

  &:active {
    transform: scale(0.9);
  }
  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }

  @media (max-width: 375px) {
    width: 100%;
    order: 2;
  }
`;

export const ButtonX = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 200px;

  background-color: ${(props) => props.colorBg || 'transparent'};
  border: none;

  position: absolute;
  right: 10px;
  top: 10px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerInformationList = styled.div`
  width: 310px;
`;

export const SpanInformationList = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;

  > div > svg {
    margin-bottom: -3px;
  }
`;
