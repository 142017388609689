import React, { useContext, useEffect, useState } from 'react';

import { ContainerExternal, Title, ContainerSwitch } from '../styled';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import SkeletonCards from '../../GenericComponents/SkeletonCards';
import ExternalJobs from '../../NewCards/ExternalJobsCards/ExternalCards';
import { GenericInternalSwitch } from 'components/GenericInternalSwitch';
import ExternalOtherJobs from '../../NewCards/ExternalOtherJobs';
import { formatNumber } from 'utils/formatNumber';

export default function TabExternalJobs(props) {
  const {
    listExternalJobOffers,
    setListExternalJobOffers,
    listExternalOtherJobs,
    setListExternalOtherJobs,
    openGamificationLevelOneIncompleteModal,
    setOpenGamificationLevelOneIncompleteModal,
    closeModal,
    openSnackbar,
    handleIsLoadingState,
    openModalWithContent,
    setGlobalState,
    openCoverLetterModal,
    setOpenCoverLetterModal,
    setHasCoverLetter,
    isLoadingExternalJobs,
    setIsLoadingExternalJobs,
    isLoadingExternalOtherJobs,
    setIsLoadingExternalOtherJobs,
    activeSubTab,
    setActiveSubTab,
  } = useContext(JobOffersContext);
  const {
    universityColor,
    academic_profile,
    interests,
    life_interests,
  } = props;

  const fetchListExternaOtherlJobs = async (query = '', fromSeeMore) => {
    if (!fromSeeMore) {
      setIsLoadingExternalOtherJobs(true);
    }
    const data = await JobOffersApi.getExternalOtherJobs(query);
    if (setListExternalOtherJobs) {
      if (fromSeeMore) {
        setListExternalOtherJobs((prevs) => ({
          ...data,
          data: {
            ...data.data,
            results: [...prevs.data.results, ...data.data.results],
          },
        }));
        if (!fromSeeMore) {
          setIsLoadingExternalOtherJobs(false);
        }
        return;
      }
      setListExternalOtherJobs(data);
    }
    if (!fromSeeMore) {
      setIsLoadingExternalOtherJobs(false);
    }
  };

  const fetchListExternalJobOffers = async (query = '', fromSeeMore) => {
    if (!fromSeeMore) {
      setIsLoadingExternalJobs(true);
    }
    const data = await JobOffersApi.getListExternalJobOffers(query);
    if (setListExternalJobOffers) {
      if (fromSeeMore) {
        setListExternalJobOffers((prevs) => ({
          ...data,
          data: {
            ...data.data,
            results: [...prevs.data.results, ...data.data.results],
          },
        }));
        if (!fromSeeMore) {
          setIsLoadingExternalJobs(false);
        }
        return;
      }
      setListExternalJobOffers(data);
    }
    if (!fromSeeMore) {
      setIsLoadingExternalJobs(false);
    }
  };

  const countExternalJobOffers = listExternalJobOffers?.data?.count || 0;
  const countExternalOthersJobOffers = listExternalOtherJobs?.data?.count || 0;

  useEffect(() => {
    fetchListExternalJobOffers();
    fetchListExternaOtherlJobs();
  }, []);

  const externalOthersJobsComponent = isLoadingExternalOtherJobs ? (
    <SkeletonCards isExternalJobs />
  ) : (
    <ExternalOtherJobs
      universityColor={universityColor}
      listExternalOtherJobs={listExternalOtherJobs}
      fetchListExternaOtherlJobs={fetchListExternaOtherlJobs}
      openGamificationLevelOneIncompleteModal={
        openGamificationLevelOneIncompleteModal
      }
      setOpenGamificationLevelOneIncompleteModal={
        setOpenGamificationLevelOneIncompleteModal
      }
      closeModal={closeModal}
      openSnackbar={openSnackbar}
      handleIsLoadingState={handleIsLoadingState}
      openModalWithContent={openModalWithContent}
      setGlobalState={setGlobalState}
      openCoverLetterModal={openCoverLetterModal}
      setOpenCoverLetterModal={setOpenCoverLetterModal}
      setHasCoverLetter={setHasCoverLetter}
      academic_profile={academic_profile}
      interests={interests}
      life_interests={life_interests}
    />
  );

  const externalJobs = isLoadingExternalJobs ? (
    <SkeletonCards isExternalJobs />
  ) : (
    <>
      <ExternalJobs
        universityColor={universityColor}
        listExternalJobOffers={listExternalJobOffers}
        countExternalJobOffers={countExternalJobOffers}
        setListExternalJobOffers={setListExternalJobOffers}
        listExternalOtherJobs={listExternalOtherJobs}
        fetchListExternalJobOffers={fetchListExternalJobOffers}
        isLoadingExternalJobs={isLoadingExternalJobs}
        setIsLoadingExternalJobs={setIsLoadingExternalJobs}
      />
    </>
  );

  const Tabs = [
    {
      activeSwitch: 0,
      component: externalOthersJobsComponent,
    },
    {
      activeSwitch: 1,
      component: externalJobs,
    },
  ];

  const handleChangeTab = (value) => {
    setActiveSubTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeSwitch === step);
    return returnTabs.component;
  };

  return (
    <ContainerExternal>
      <ContainerSwitch>
        <GenericInternalSwitch
          activeTab={activeSubTab}
          handleChange={handleChangeTab}
          tabs={[
            `Outras vagas (${formatNumber(
              countExternalOthersJobOffers || '0'
            )})`,
            `Vagas de parceiros (${formatNumber(
              countExternalJobOffers || '0'
            )})`,
          ]}
        />
      </ContainerSwitch>
      {renderPrincipalTabs(activeSubTab)}
    </ContainerExternal>
  );
}
