import { useContext, useState, useEffect } from 'react';
import {
  CallToAction,
  Container,
  ExperiencesContainer,
  Divider,
  Section,
  Title,
  ButtonsContainer,
  TableWrapper,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableCell,
} from './style';
import { IoIosArrowForward } from 'react-icons/io';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import ExperienceForm from 'views/MyProfile/components/Experiences/components/ExperienceForm/ExperienceForm';
import { GenericButtons } from '../GenericButtons';
import ModalInformation from 'components/informationModal';
import {
  deleteProfessionalExperience,
  getProfessionalExperience,
} from 'services/student/professionalExperience';
import { useDataManagement } from 'views/Profile/Hooks/useDataManagement';
import { loadExperience } from 'adapters/student/experiencesAdapter';
import { profileTexts } from 'views/Profile/Utils/profileTexts';
import { deleteLifeExperience } from 'services/student/lifeExperience';
import useMediaQuery from 'hooks/useMediaQuery';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export function ExperiencesContent(props) {
  const {
    cardData,
    cardDataKey,
    cardDataKeyTwo,
    setCardData,
    openModalWithContent,
    handleIsLoadingState,
    setUserData,
    shouldHideItem,
    updateQuizz,
  } = props;

  const { universityColor, fetchGamificationData, visibleInCurriculum } =
    useContext(ProfileContext);
  const [experienceFormChosen, setExperienceFormChosen] = useState('');
  const [deleteCareerExperience, setDeleteCareerExperience] = useState(false);
  const [deleteLifeExperienceState, setDeleteLifeExperience] = useState(false);
  const [visibleCareerExperiences, setVisibleCareerExperiences] = useState([]);
  const [visibleLifeExperiences, setVisibleLifeExperiences] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { handleVisibleItem } = useDataManagement(
    setUserData,
    setDisabled,
    disabled
  );
  const { configExcludeExperience } = profileTexts();
  const smMobile = useMediaQuery('(max-width: 374px)');

  useEffect(() => {
    cardData[cardDataKey] && setVisibleCareerExperiences(cardData[cardDataKey]);
    cardData[cardDataKeyTwo] &&
      setVisibleLifeExperiences(cardData[cardDataKeyTwo]);
  }, []);

  useEffect(() => {
    setVisibleCareerExperiences(visibleInCurriculum.experience);
    setVisibleLifeExperiences(visibleInCurriculum.life_experience);
  }, [visibleInCurriculum]);

  // Career experiences
  const handleToggleVisibilityCareerExperience = (item) => {
    let updatedVisibleCareerExperiences = [...visibleCareerExperiences];

    if (visibleCareerExperiences.includes(item.id)) {
      updatedVisibleCareerExperiences = updatedVisibleCareerExperiences.filter(
        (id) => id !== item.id
      );
    }
    if (!visibleCareerExperiences.includes(item.id)) {
      if (visibleCareerExperiences.length === 3) {
        return customSnackbar(
          `Suas três experiências relevantes já estão selecionadas!`,
          'alert'
        );
      }
      updatedVisibleCareerExperiences = [...visibleCareerExperiences, item.id];
    }

    const visibilityItemConfig = {
      items: updatedVisibleCareerExperiences,
      item: item,
      reqKey: 'experience',
      isUpdate: true,
      cardData,
      dataKey: cardDataKey,
      setData: setCardData,
      updateKey: 'professional_experience',
      setVisibleItems: setVisibleCareerExperiences,
    };
    handleVisibleItem(visibilityItemConfig);
  };

  const excludeCareerExperience = async (experienceId) => {
    setDeleteCareerExperience(false);
    handleIsLoadingState(true);
    await deleteProfessionalExperience(experienceId);
    const response = await getProfessionalExperience();
    setCardData((prev) => ({
      ...prev,
      [cardDataKey]: response.professional_experience,
    }));
    if (typeof updateQuizz === 'function') {
      updateQuizz('careerExperience', response.professional_experience);
    }
    await fetchGamificationData();
    if (visibleCareerExperiences.includes(experienceId)) {
      const updatedVisibleExperience = visibleCareerExperiences.filter(
        (id) => id !== experienceId
      );
      setVisibleCareerExperiences(updatedVisibleExperience);
      setUserData({ experience: updatedVisibleExperience });
    }

    handleIsLoadingState(false);
    customSnackbar('Experiência excluída com sucesso!', 'confirmation');
  };

  useEffect(() => {
    if (props.location.state && props.location.state.addExperience) {
      openModalWithContent(
        mergeContextsHelper(
          <ExperienceForm
            isCareer
            cardData={cardData}
            cardDataKey={cardDataKey}
            setCardData={setCardData}
            setUserData={setUserData}
            handleVisibleItem={handleVisibleItem}
            visibleCareerExperiences={visibleCareerExperiences}
            setVisibleCareerExperiences={setVisibleCareerExperiences}
            setExperienceFormChosen={setExperienceFormChosen}
            fetchGamificationData={fetchGamificationData}
            updateQuizz={updateQuizz}
            {...props}
          />
        ),
        'Adicionar experiência',
        null,
        () => null,
        false,
        experienceFormChosen
      );
      props.location.state.addExperience = false;
    }
  }, []);

  const callToCareerExperiencesForm = () => {
    openModalWithContent(
      mergeContextsHelper(
        <ExperienceForm
          isCareer
          cardData={cardData}
          cardDataKey={cardDataKey}
          setCardData={setCardData}
          setUserData={setUserData}
          handleVisibleItem={handleVisibleItem}
          visibleCareerExperiences={visibleCareerExperiences}
          setVisibleCareerExperiences={setVisibleCareerExperiences}
          setExperienceFormChosen={setExperienceFormChosen}
          fetchGamificationData={fetchGamificationData}
          updateQuizz={updateQuizz}
          {...props}
        />
      ),
      'Adicionar Experiência de Carreira',
      null,
      () => null,
      false,
      experienceFormChosen
    );
  };

  const callToEditCareerExperiences = (item) => {
    openModalWithContent(
      mergeContextsHelper(
        <ExperienceForm
          isEdit
          isCareer
          cardData={cardData}
          cardDataKey={cardDataKey}
          experience={loadExperience(item)}
          setCardData={setCardData}
          setUserData={setUserData}
          handleVisibleItem={handleVisibleItem}
          visibleCareerExperiences={visibleCareerExperiences}
          setVisibleCareerExperiences={setVisibleCareerExperiences}
          fetchGamificationData={fetchGamificationData}
          updateQuizz={updateQuizz}
          {...props}
        />
      ),
      'Editar Experiência de Carreira',
      null,
      () => null,
      false,
      experienceFormChosen
    );
  };

  // Life experiences
  const handleToggleVisibilityLifeExperience = (item) => {
    let updatedVisibleLifeExperiences = [...visibleLifeExperiences];
    if (visibleLifeExperiences.includes(item.id)) {
      updatedVisibleLifeExperiences = updatedVisibleLifeExperiences.filter(
        (id) => id !== item.id
      );
    }
    if (!visibleLifeExperiences.includes(item.id)) {
      if (visibleLifeExperiences.length === 3) {
        customSnackbar(
          `Suas três experiências relevantes ja estão selecionadas!`,
          'alert'
        );
        return;
      }
      updatedVisibleLifeExperiences = [...visibleLifeExperiences, item.id];
    }

    const visibilityItemConfig = {
      items: updatedVisibleLifeExperiences,
      item: item,
      reqKey: 'life_experience',
      isUpdate: true,
      cardData,
      dataKey: cardDataKeyTwo,
      setData: setCardData,
      updateKey: 'life_experience',
      setVisibleItems: setVisibleLifeExperiences,
    };
    handleVisibleItem(visibilityItemConfig);
  };

  const excludeLifeExperience = async (experienceId) => {
    setDeleteLifeExperience(false);
    handleIsLoadingState(true);
    await deleteLifeExperience(experienceId);
    const response = await getProfessionalExperience();
    setCardData((prev) => ({
      ...prev,
      [cardDataKeyTwo]: response.life_experience,
    }));
    if (typeof updateQuizz === 'function') {
      updateQuizz('lifeExperience', response.life_experience);
    }

    await fetchGamificationData();

    if (visibleLifeExperiences.includes(experienceId)) {
      const updatedVisibleExperience = visibleLifeExperiences.filter(
        (id) => id !== experienceId
      );
      setVisibleLifeExperiences(updatedVisibleExperience);
      setUserData({ life_experience: updatedVisibleExperience });
    }

    handleIsLoadingState(false);
    customSnackbar('Experiência excluída com sucesso!', 'confirmation');
  };

  const callToLifeExperiencesForm = () => {
    openModalWithContent(
      mergeContextsHelper(
        <ExperienceForm
          isCareer={false}
          setCardData={setCardData}
          setUserData={setUserData}
          cardDataKeyTwo={cardDataKeyTwo}
          handleVisibleItem={handleVisibleItem}
          visibleLifeExperiences={visibleLifeExperiences}
          setVisibleLifeExperiences={setVisibleLifeExperiences}
          setExperienceFormChosen={setExperienceFormChosen}
          fetchGamificationData={fetchGamificationData}
          updateQuizz={updateQuizz}
          {...props}
        />
      ),
      'Adicionar Experiência de Vida',
      null,
      () => null,
      false,
      experienceFormChosen
    );
  };

  const callToEditLifeExperiences = (item) => {
    openModalWithContent(
      mergeContextsHelper(
        <ExperienceForm
          isEdit
          isCareer={false}
          experience={loadExperience(item)}
          setCardData={setCardData}
          cardDataKey={cardDataKey}
          visibleLifeExperiences={visibleLifeExperiences}
          setVisibleLifeExperiences={setVisibleLifeExperiences}
          fetchGamificationData={fetchGamificationData}
          updateQuizz={updateQuizz}
          {...props}
        />
      ),
      'Editar Experiência de Vida',
      null,
      () => null,
      false,
      experienceFormChosen
    );
  };

  const renderTable = (array, funcVisibility, funcDelete, funcEdit) => {
    return (
      <ExperiencesContainer margin={array.length > 0}>
        <TableWrapper>
          <Table>
            {array.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Cargo</TableHeaderCell>
                  {!smMobile && <TableHeaderCell>Período</TableHeaderCell>}
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
            )}
            <tbody>
              {array.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <h3>{item.role}</h3>
                    <p>{item.company}</p>
                  </TableCell>
                  {!smMobile && (
                    <TableCell>
                      <p>{`${item.started_at} - ${item.ended_at}`}</p>
                    </TableCell>
                  )}
                  <TableCell>
                    <ButtonsContainer>
                      <GenericButtons
                        showEdit={true}
                        showDelet={true}
                        showEye={true}
                        disabled={disabled}
                        shouldHideItem={shouldHideItem}
                        visible={item.visible}
                        handleFuncEdit={() => funcEdit(item)}
                        handleFuncDelet={() => funcDelete(item.id)}
                        handleFuncVisibility={() => funcVisibility(item)}
                      />
                    </ButtonsContainer>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </ExperiencesContainer>
    );
  };

  return (
    <Container>
      {deleteCareerExperience && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={deleteCareerExperience}
          config={configExcludeExperience}
          buttonConfirmFunction={() =>
            excludeCareerExperience(deleteCareerExperience)
          }
          buttonCancelFunction={() => setDeleteCareerExperience(false)}
        />
      )}
      {deleteLifeExperienceState && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={deleteLifeExperienceState}
          config={configExcludeExperience}
          buttonConfirmFunction={() =>
            excludeLifeExperience(deleteLifeExperienceState)
          }
          buttonCancelFunction={() => setDeleteLifeExperience(false)}
        />
      )}
      <Section>
        <Title>Experiências de Carreira</Title>
        {renderTable(
          cardData[cardDataKey],
          handleToggleVisibilityCareerExperience,
          setDeleteCareerExperience,
          callToEditCareerExperiences
        )}
        <CallToAction
          disabled={shouldHideItem}
          universityColor={universityColor}
          onClick={() => callToCareerExperiencesForm()}
          showButton
        >
          Adicionar <IoIosArrowForward />
        </CallToAction>
      </Section>

      <Divider />

      <Section>
        <Title>Experiências de Vida</Title>
        {renderTable(
          cardData[cardDataKeyTwo],
          handleToggleVisibilityLifeExperience,
          setDeleteLifeExperience,
          callToEditLifeExperiences
        )}
        <CallToAction
          disabled={shouldHideItem}
          universityColor={universityColor}
          showButton
          onClick={() => callToLifeExperiencesForm()}
        >
          Adicionar <IoIosArrowForward />
        </CallToAction>
      </Section>
    </Container>
  );
}
