import React, { useState, useContext, useRef, useEffect } from 'react';
import { ModalsContext } from 'components/modals/contexts/modalContext';
import CustomButton from 'components/CustomButtons/Button';
import {
  IndicateModal,
  Loading,
  ButtonIndicateForJob,
  OutlineButton,
} from './style';
import { Form } from '@unform/web';
import Input from '../../../../views/StaffPages/Opportunities/Components/Form/input';
import { indicateForJob } from '../../services/talents.service';
import BaseLayoutContext from 'contexts/base-layout';
import Checkbox from '@material-ui/core/Checkbox';

const IndicationModal = (props) => {
  const [showModal, setShowModal] = useState(true);
  const [code, setCode] = useState(true);
  const {
    children,
    jobs,
    closeModalFunc,
    talent,
    updateIndicationsCount,
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [currentMessage, setCurrentMessage] = useState('initialMessage');
  const { openSnackbar } = useContext(BaseLayoutContext);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showRequiredField, setShowRequiredField] = useState(false);

  const exitModal = async () => {
    await closeModalFunc();
  };

  useEffect(() => {
    if (!talent.accept_be_indicated) {
      setCurrentMessage('dontBeIndicated');
    }
  }, [talent]);

  const setContent = () => {
    const { code: inputCode } = formRef.current.getData();
    if (!inputCode || inputCode == '') {
      setShowRequiredField(true);
      return;
    }
    setCode(inputCode);
    if (talent.is_active == false) {
      setCurrentMessage('dontHasAccess');
      return;
    }
    if (talent.has_curriculum == false) {
      setCurrentMessage('dontHasCurriculum');
      return;
    }
    setCurrentMessage('confirm');
  };

  const confirmIndication = async () => {
    try {
      const response = await indicateForJob({
        student: talent.id,
        jobOffer: code,
      });
      await updateIndicationsCount();
      exitModal();
      openSnackbar('Sucesso');
    } catch (e) {
      if (e.response.hasOwnProperty('data')) {
        const { data, status } = e.response;
        if (status == 400 && data?.detail?.offer_is_available === 'False') {
          setCurrentMessage('notAvailable');
          return;
        } else if (status == 400 && data?.detail?.exists_offer === 'False') {
          setCurrentMessage('dontExistsOffer');
          return;
        }
      }

      openSnackbar('Não foi possivel indicar o estudante', 'error');
      exitModal();
    }
  };

  const nextStep = () => {
    if (acceptTerms) {
      setCurrentMessage('confirm');
    }
  };

  const contents = {
    initialMessage: {
      message: `Digite o código da vaga para indicar o estudante ${talent.name}.`,
      bubbleText: `Indicar estudante`,
      input: (
        <>
          <div className="inputContainer">
            <Input
              name="code"
              type="text"
              placeholder="Digite aqui..."
              error={showRequiredField}
              onChange={() => {
                showRequiredField && setShowRequiredField(false);
              }}
            />
            {showRequiredField && (
              <span className="error">Campo obrigatório</span>
            )}
          </div>
        </>
      ),
      buttons: (
        <div className="buttonContainer">
          <CustomButton
            disabled={loading}
            className="actionButton"
            onClick={setContent}
            type="button"
          >
            {loading ? <Loading /> : <>INDICAR ESTUDANTE</>}
          </CustomButton>
        </div>
      ),
    },
    dontHasCurriculum: {
      message: `Você está indicando um estudante que não possui currículo. Por esse motivo, o perfil do estudante não ficará disponível imediatamente para a empresa. Mas enviaremos um e-mail notificando o estudante da sua indicação.`,
      bubbleText: `Atenção!`,
      hasCheck: true,
      buttons: (
        <div className="buttonContainer">
          <CustomButton
            onClick={() => nextStep()}
            className={acceptTerms ? '' : 'customDisabled'}
            disabled={!acceptTerms}
            fullWidth
          >
            Avançar
          </CustomButton>
        </div>
      ),
    },
    dontHasAccess: {
      message: `Você está indicando um estudante que ainda não acessou a plataforma. Mas enviaremos um e-mail notificando o estudante da sua indicação.`,
      bubbleText: `Atenção!`,
      hasCheck: true,
      buttons: (
        <>
          <CustomButton
            onClick={() => nextStep()}
            className={acceptTerms ? '' : 'customDisabled'}
            disabled={!acceptTerms}
            fullWidth
          >
            Avançar
          </CustomButton>
        </>
      ),
    },
    dontBeIndicated: {
      message: `No momento, este estudante optou por não ser indicado à vagas de emprego e por isso ele não será indicado para esta vaga.`,
      bubbleText: `Ops!!`,
      buttons: (
        <div className="buttonContainer">
          <CustomButton
            disabled={loading}
            className="actionButton"
            onClick={exitModal}
            type="button"
          >
            OK, ENTENDI
          </CustomButton>
        </div>
      ),
    },
    notAvailable: {
      message:
        'A vaga informada não é compatível com o perfil deste estudante e por isso ele não será indicado para esta vaga.',
      bubbleText: `Atenção!`,
      buttons: (
        <div className="buttonContainer">
          <CustomButton
            disabled={loading}
            className="actionButton"
            onClick={exitModal}
            type="button"
          >
            OK, ENTENDI
          </CustomButton>
        </div>
      ),
    },
    dontExistsOffer: {
      message: 'O código fornecido não corresponde a nenhuma vaga disponível.',
      bubbleText: `Oops!`,
      buttons: (
        <div className="buttonContainer">
          <CustomButton
            disabled={loading}
            className="actionButton"
            onClick={exitModal}
            type="button"
          >
            OK, ENTENDI
          </CustomButton>
        </div>
      ),
    },
    confirm: {
      message: (
        <>
          {`Tem certeza de que deseja indicar ${talent.name} para esta vaga?`}
          <br />
          <br />
          Esta ação não poderá ser desfeita.
        </>
      ),
      bubbleText: `Atenção!`,
      buttons: (
        <div className="flex">
          <OutlineButton onClick={exitModal} disabled={loading}>
            NÃO
          </OutlineButton>
          <CustomButton
            onClick={confirmIndication}
            className="actionButton"
            fullWidth
            disabled={loading}
          >
            {loading ? <Loading /> : 'SIM, QUERO INDICAR'}
          </CustomButton>
        </div>
      ),
    },
  };

  const handleCheckbox = (event) => {
    setAcceptTerms(event.target.checked);
  };

  return (
    <IndicateModal
      isOpen={showModal}
      bubbleText={contents[currentMessage].bubbleText}
      widthBubble={'200px'}
      bubbleClass="bubble"
      closeModalFunc={exitModal}
    >
      <>
        <p className="message">{contents[currentMessage].message}</p>
        {contents[currentMessage].hasCheck && (
          <div className="acceptTerms">
            <Checkbox
              onChange={handleCheckbox}
              classes={{
                root: 'customCheck',
                checked: 'customChecked',
              }}
            />
            <span>Estou ciente e quero prosseguir.</span>
          </div>
        )}
        <Form ref={formRef} onSubmit={setContent}>
          {contents[currentMessage].input ?? ''}
          {contents[currentMessage].buttons}
        </Form>
      </>
    </IndicateModal>
  );
};

export const IndicateStudentForJob = (props) => {
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const { children, updateIndicationsCount, ...rest } = props;
  const { createModal } = useContext(ModalsContext);

  const handleOpenModal = () => {
    setOpenStudentModal(!openStudentModal);
  };

  const openModal = () => {
    const modal = createModal();
    modal.setContent(
      <IndicationModal {...props} closeModalFunc={() => modal.closeModal()} />
    );
  };

  return (
    <>
      <ButtonIndicateForJob
        {...rest}
        onClick={openModal}
        updateIndicationsCount={updateIndicationsCount}
      >
        {children}
      </ButtonIndicateForJob>
    </>
  );
};
