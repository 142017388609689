import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import SearchableSelect from 'react-select';
import { Mixpanel } from 'utils/mixpanel';

import {
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { func, bool, shape, arrayOf } from 'prop-types';
import { getInterests, saveInterests } from 'services/student/interests';

import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { triggerHotjarEvent } from 'utils/hotjar';
import { triggerPageView } from '../../../../utils/analytics';
import SaveButtonsTests from 'components/SaveButtonsTests';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

const InterestSchema = Yup.object().shape({
  area: Yup.array()
    .of(Yup.string())
    .max(5, 'Você deve escolher no máximo 5 interesses para manter o foco.')
    .min(1, 'Você deve escolher de 1 a 5 interesses.')
    .nullable(),
});

function InterestForm(props) {
  const {
    classes,
    closeModal,
    handleIsLoadingState,
    selectedInterests,
    lifeInterest,
    isFromJobOffers,
    setClose,
    isMyProfile,
    metadata,
    updateInterestState,
    cardDataKey,
    cardDataKeyTwo,
    setCardData,
    fetchGamificationData,
    updateQuizz,
  } = props;

  const [initialValues, setInitialValues] = useState({ area: null });
  const [interestList, setInterestList] = useState([]);
  const [formChange, setFormChange] = useState(false);
  let newInterests = null;

  const handleInterests = async () => {
    const interests = await getInterests();

    if (lifeInterest) {
      setInterestList(interests.life_interests);
      if (metadata?.university_slug !== 'uniexemplo') {
        triggerHotjarEvent('life_interests');
      }
    } else {
      setInterestList(interests.area);
      if (metadata?.university_slug !== 'uniexemplo') {
        triggerHotjarEvent('career_interests');
      }
    }

    handleIsLoadingState(false);
  };

  useEffect(() => {
    if (lifeInterest) {
      Mixpanel.track('Abriu Formulário Interesses de Vida');
    } else {
      Mixpanel.track('Abriu Formulário Interesses de Carreira');
    }

    handleInterests();
    setInitialValues({ area: selectedInterests });
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={InterestSchema}
      onSubmit={async (values, actions) => {
        if (formChange) {
          handleIsLoadingState(true);
          if (lifeInterest) {
            newInterests = await saveInterests({
              life_interests: values.area.map((item) => item.value),
            });
            triggerPageView('student/interesses/vida/cadastro');
          } else {
            newInterests = await saveInterests({
              interests: values.area.map((item) => item.value),
            });
            triggerPageView('student/interesses/carreira/cadastro');
          }
          if (newInterests.message) {
            customSnackbar(
              `Erro ao salvar o interesse! Tente novamente mais tarde.`,
              'error'
            );
            actions.setSubmitting(false);
          }
          updateInterestState && updateInterestState(newInterests);
          cardDataKey &&
            setCardData((prev) => ({
              ...prev,
              [cardDataKey]: newInterests.interests,
            }));

          if (typeof updateQuizz === 'function') {
            updateQuizz('careerInterests', newInterests.interests);
          }
          cardDataKeyTwo &&
            setCardData((prev) => ({
              ...prev,
              [cardDataKeyTwo]: newInterests.life_interests,
            }));
          if (typeof updateQuizz === 'function') {
            updateQuizz('lifeInterests', newInterests.life_interests);
          }

          await fetchGamificationData();
          actions.setSubmitting(false);
          if (isFromJobOffers || isMyProfile) setClose();
          if (lifeInterest) {
            Mixpanel.track('Salvou Formulário Interesses de Vida');
          } else {
            Mixpanel.track('Salvou Formulário Interesses de Carreira');
          }
          closeModal();
          customSnackbar(`Interesses salvos com sucesso!`, 'confirmation');
        } else {
          closeModal();
          customSnackbar('Nenhuma alteração realizada!', 'confirmation');
        }
        setClose();
        handleIsLoadingState(false);
      }}
      render={({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {/* Area */}
          <Grid container justify="center" spacing={24} style={{ padding: 13 }}>
            <Grid
              md={12}
              sm={12}
              xs={12}
              className={classes.interestHeaderText}
            >
              <p className={classes.cardCategory}>
                {lifeInterest
                  ? `Selecione até 5 interesses de vida (hobbies, paixões)
                      que mais brilham seus olhos.`
                  : `Selecione até 5 interesses de carreira (áreas, setores, temas, assuntos)
                      que mais brilham seus olhos.`}
              </p>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="area"
                  shrink
                  error={errors.area && touched.area}
                >
                  Interesses de {lifeInterest ? 'Vida' : 'Carreira'}
                </InputLabel>
                <SearchableSelect
                  name="area"
                  id="area"
                  isClearable
                  InputLabel={{
                    shrink: true,
                  }}
                  isMulti
                  multiline
                  rows={3}
                  placeholder="Digite ou Escolha"
                  isSearchable
                  classNamePrefix="react-select"
                  className={classNames([
                    classes.selectSection,
                    classes.selectSectionAutoHeight,
                  ])}
                  onBlur={(event) => setFieldTouched('area', event.label)}
                  onChange={(event) => {
                    setFormChange(true);
                    if (event.length <= 5) {
                      setFieldValue('area', event);
                    }
                  }}
                  options={interestList || []}
                  defaultValue={selectedInterests}
                  value={values.area}
                />
              </FormControl>
              {!!errors.area ? (
                !!touched.area && (
                  <FormHelperText className={classes.helperText}>
                    {errors.area}
                  </FormHelperText>
                )
              ) : (
                <FormHelperText className={classes.helperText}>
                  Até 5 conhecimentos
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={12} xs={12} style={{ display: 'flex' }}>
              <SaveButtonsTests disabled={isSubmitting} type="submit" round />
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
}

InterestForm.defaultProps = {
  lifeInterest: false,
  isMyProfile: false,
  isFromJobOffers: false,
  setClose: () => {},
};

InterestForm.propTypes = {
  isFromJobOffers: bool,
  setClose: func,
  isMyProfile: bool,
  classes: shape({}).isRequired,
  closeModal: func.isRequired,
  openSnackbar: func.isRequired,
  handleIsLoadingState: func.isRequired,
  selectedInterests: arrayOf({}).isRequired,
  lifeInterest: bool,
  metadata: shape({}).isRequired,
  academicProfile: shape({}).isRequired,
};
export default withStyles(ProfessionalExperienceFormStyles)(InterestForm);
