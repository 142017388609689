import React, { useContext, useState } from 'react';
import {
  AlertTest,
  CallToAction,
  Chip,
  ChipContainer,
  Container,
  Paragraph,
  Section,
} from './style';
import { IoIosArrowForward } from 'react-icons/io';
import BehavioralTest from 'views/MyProfile/components/BehavioralTest/BehavioralTest';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import ModalInformation from 'components/informationModal';
import moment from 'moment';

export function BehavioralContent({
  cardData,
  cardDataKey,
  academic_profile,
  setCardData,
  openModalWithContent,
  updateQuizz,
}) {
  const { universityColor, fetchGamificationData } = useContext(ProfileContext);
  const [controlInformationModal, setControlInformationModal] = useState(false);

  const callToBehavioralTest = () => {
    setControlInformationModal(false);
    openModalWithContent(
      mergeContextsHelper(
        <BehavioralTest
          indicator={cardData[cardDataKey]}
          cardData={cardData}
          setCardData={setCardData}
          cardDataKey={cardDataKey}
          fetchGamificationData={fetchGamificationData}
          updateQuizz={updateQuizz}
        />
      ),
      'Questionário de perfil comportamental',
      null,
      () => null,
      false,
      'behavioral'
    );
  };

  const config = {
    modalWidth: 500,
    bubbleText: `Olá, ${academic_profile?.name}!`,
    ParagraphText: `Recomendo que você tenha um tempinho dedicado para realizar o seu
    questionário com calma, pois, após realizar a conclusão o seu
    resultado ficará exposto em seu currículo e você só poderá refazê-lo
    daqui <b>6 meses!</b>`,
    buttonConfirmText: 'Estou com tempo, bora lá!',
    buttonConfirmFunction: callToBehavioralTest,
    buttonCloseX: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  const showCTAPersonality = () => {
    if (cardData[cardDataKey].is_done === false) {
      return (
        <CallToAction
          showButton={cardData[cardDataKey].is_done === false}
          universityColor={universityColor}
          onClick={() => setControlInformationModal(true)}
        >
          Realizar questionário <IoIosArrowForward />
        </CallToAction>
      );
    }

    if (cardData[cardDataKey].is_enabled) {
      return (
        <CallToAction
          showButton={cardData[cardDataKey].is_enabled}
          universityColor={universityColor}
          onClick={() => setControlInformationModal(true)}
        >
          Refazer questionário <IoIosArrowForward />
        </CallToAction>
      );
    }
  };

  return (
    <Container>
      <Section>
        {controlInformationModal && (
          <ModalInformation
            universityColor={universityColor}
            modalOpenState={controlInformationModal}
            config={config}
            buttonConfirmFunction={config.buttonConfirmFunction}
            closeModalFunction={() => setControlInformationModal(false)}
          />
        )}
        {cardData[cardDataKey]?.results.length > 0 && (
          <>
            <ChipContainer direction="row">
              {cardData[cardDataKey]?.results.map(({ verbose_name }) => (
                <Chip>{verbose_name}</Chip>
              ))}
            </ChipContainer>
            <Paragraph>
              {`${academic_profile?.name}`}, seu perfil se diferencia pelas suas
              capacidades de{' '}
              {cardData[cardDataKey]?.results.map((e) =>
                e.description.replace('.', '; ')
              )}{' '}
              Acredite no seu potencial, invista em você e construa uma carreira
              que potencialize o seu perfil comportamental!
            </Paragraph>
          </>
        )}
        {showCTAPersonality()}
        {!cardData[cardDataKey].is_enabled && (
          <AlertTest showAlert={!cardData[cardDataKey].is_enabled}>
            Este questionário poderá ser refeito em{' '}
            {moment(cardData[cardDataKey].next_date).format('DD/MM/YYYY')}
          </AlertTest>
        )}
      </Section>
    </Container>
  );
}
