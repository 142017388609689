import { onlyNumbers } from 'utils/inputMasks';
import { IndicateStudentForJob } from '../IndicateForJob';
import { TalentItemContainer, CurriculumModal } from './style';
import BaseLayoutContext from 'contexts/base-layout';
import { useCallback, useContext, useState } from 'react';
import { getStudentCurriculumData } from 'services/company/curriculum';
import { TalentsContext } from 'components/NewTalentList/contexts/TalentListContext';
import IndicationJobsModal from 'views/StaffPages/jobOpeningsRebuild/components/IndicationStudent/components/Modals/IndicationJobsModal';

export function TalentItem({ talent, isIndication = false }) {
  const [openIndicationModal, setOpenIndicationModal] = useState(false);
  const { openCurriculum, updateIndicationsCount } = useContext(TalentsContext);
  /**
   * @param {string} status
   * @returns {string}
   */

  const defineHighligthText = () => {
    if (!talent?.is_active) {
      return 'Estudante inativo';
    } else if (talent?.gamification_level === 3) {
      return 'Nível 3 - Perfil campeão';
    } else if (talent?.gamification_level === 2) {
      return 'Nível 2 - Jornada do protagonismo';
    } else if (talent?.gamification_level === 1) {
      return 'Nível 1- Jornada do autoconhecimento';
    } else {
      return '';
    }
  };

  const whatsUrl = (phone) => {
    const number = onlyNumbers(phone);
    return `https://wa.me/55${number}`;
  };

  const closeModal = () => {
    setOpenIndicationModal(false);
  };

  return (
    <TalentItemContainer>
      <div className="row">
        <div className="firstColumn">
          <h3 className="name">{talent.name}</h3>
          <div className="infos">
            <p className="highlight">{defineHighligthText()}</p>
            <p>
              <b>Instituição:</b> {talent?.university_name}
            </p>
            {talent?.courses?.map((c) => (
              <p>
                <b>Curso:</b> {c.name} - {c?.semester}º semestre
              </p>
            ))}
            <span>
              <p>
                <b>E-mail:</b> {talent.email ?? 'Não informado'}
              </p>
              <p>
                <b>Telefone:</b>{' '}
                {talent?.mobile_phone ? (
                  <a
                    className="phone"
                    href={whatsUrl(talent.mobile_phone)}
                    target="_blank"
                  >
                    {talent.mobile_phone}
                  </a>
                ) : (
                  'Não informado'
                )}
              </p>
            </span>
            <p>
              <b>Localização:</b> {talent?.city}/{talent?.state}
            </p>
          </div>
        </div>
        <div className="secondColumn">
          {isIndication ? (
            <>
              <button
                className="openCurriculum"
                onClick={() => setOpenIndicationModal(true)}
              >
                Vagas indicadas ({talent.nominee_quantity})
              </button>

              {talent.has_curriculum && (
                <button
                  className="curriculumIndication"
                  onClick={() => openCurriculum(talent)}
                >
                  Visualizar currículo
                </button>
              )}
            </>
          ) : (
            <>
              {talent.has_curriculum && (
                <button
                  className="openCurriculum"
                  onClick={() => openCurriculum(talent)}
                >
                  Visualizar currículo
                </button>
              )}
              <IndicateStudentForJob talent={talent} updateIndicationsCount={updateIndicationsCount}>
                Indicar estudante
              </IndicateStudentForJob>
            </>
          )}
        </div>
      </div>

      {openIndicationModal && (
        <IndicationJobsModal
          isOpen={openIndicationModal}
          closeModalFunc={closeModal}
          student={talent}
          isTalentListScope
        />
      )}
    </TalentItemContainer>
  );
}
