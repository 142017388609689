import React, { useState, useContext, useEffect } from 'react';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import GamificationLevelOneIncompleteModal from '../../Tabs/TabsJobOffers/GamificationLevelOneIncompleteModal';
import BlankModalInformation from 'components/BlankInformationModal';
import { coverLetterConfig } from '../../Card/useCases/IModalConfig';
import { checkHasCoverLetter } from 'services/student/profile';
import { openCoverLetterForm } from 'components/JobDetailsModal/useCases/utils';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import CurriculumContextProvider from 'contexts/CurriculumContext';
import ExternalOtherJobsComponent from './ExternalOtherJobsComponent';
import { ContainerGenericMessage } from '../styled';
import GenericMessage from 'components/GenericMessage';
import ModalInformation from 'components/informationModal';

const ExternalOtherJobs = ({
  listExternalOtherJobs,
  universityColor,
  fetchListExternaOtherlJobs,
  openGamificationLevelOneIncompleteModal,
  setOpenGamificationLevelOneIncompleteModal,
  closeModal,
  openSnackbar,
  handleIsLoadingState,
  academic_profile,
  interests,
  life_interests,
}) => {
  const dataExternalOtherJobOffers = listExternalOtherJobs?.data?.results;
  const [isApplying, setIsApplying] = useState(false);
  const [
    jobIdCandidatureBeingProcessed,
    setJobIdCandidatureBeingProcessed,
  ] = useState(null);
  const { gamificationLevel } = useContext(ProfileContext);
  const gamificationLevelOneIncomplete = gamificationLevel <= 1;
  const [openModalExternalJob, setOpenModalExternalJob] = useState(false);
  const [selectUrl, setSelectUrl] = useState(null);
  const [selectJobEmail, setSelectJobEmail] = useState(null);
  const [selectJobId, setSelectJobId] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const isGamificationLevelOneIncomplete = () => {
    setOpenGamificationLevelOneIncompleteModal(gamificationLevelOneIncomplete);
    return gamificationLevelOneIncomplete;
  };

  const config = {
    bubbleText: `Atenção!`,
    ParagraphText: `
      O processo de candidatura para esta vaga é realizado externamente. 
      Por isso, você será redirecionado para outro link para se candidatar.
    `,
    TwoParagraphText: `
      Ao clicar em "continuar", você será levado à página de candidatura, 
      e esta vaga será automaticamente adicionada à sua aba "candidaturas".
    `,
    buttonConfirmText: 'CONTINUAR',
    buttonCancelText: 'DESISTIR DA CANDIDATURA',
    showButtonCancel: true,
    buttonCancelWidth: 290,
    buttonCloseX: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  const onClickDetails = (jobId, url, email) => {
    setOpenDetailsModal(true);
    setSelectJobId(jobId);
    setSelectUrl(url);
    setSelectJobEmail(email);
  };

  const handleCloseModalExternal = () => {
    setOpenDetailsModal(false);
  };

  const handleCloseModalExternalApply = () => {
    setOpenModalExternalJob(false);
  };

  const openExternalModal = (jobId, url, email) => {
    if (isGamificationLevelOneIncomplete()) {
      return;
    } else {
      setOpenModalExternalJob(true);
      setSelectJobId(jobId);
      setSelectUrl(url);
      setSelectJobEmail(email);
    }
  };

  async function applyToJobOfferExternal(jobId) {
    try {
      if (isApplying) {
        return;
      }

      const data = { job_offer: jobId };
      setIsApplying(true);
      const response = await JobOffersApi.getCandidateJobOffer(data);
      await fetchListExternaOtherlJobs(false);
      if (response.message) {
        customSnackbar(
          'Erro ao candidatar a vaga, tente novamente mais tarde.',
          'error'
        );
      } else {
        customSnackbar(
          'Agora você está concorrendo a essa vaga!',
          'confirmation'
        );
      }
    } catch (error) {
      customSnackbar(
        'Erro inesperado ao candidatar a vaga, tente novamente mais tarde.',
        'error'
      );
    } finally {
      setIsApplying(false);
    }
  }

  async function candidateJobOffer(jobId) {
    try {
      if (isGamificationLevelOneIncomplete()) {
        return;
      }

      setJobIdCandidatureBeingProcessed(jobId);

      await applyToJobOfferExternal(jobId);
    } finally {
      setJobIdCandidatureBeingProcessed(null);
    }
  }

  const handleExternalApply = async (jobId, url, email) => {
    if (url !== '') {
      window.open(url, '_blank');
    } else if (email !== '') {
      window.location.href = `mailto:${email}`;
    }
    await candidateJobOffer(jobId);
    handleCloseModalExternal();
    handleCloseModalExternalApply();
  };

  return (
    <>
      {dataExternalOtherJobOffers?.length > 0 ? (
        <CurriculumContextProvider>
          <>
            <ExternalOtherJobsComponent
              universityColor={universityColor}
              dataExternalOtherJobOffers={dataExternalOtherJobOffers}
              jobIdCandidatureBeingProcessed={jobIdCandidatureBeingProcessed}
              candidateJobOffer={(jobId, url, email) =>
                openExternalModal(jobId, url, email)
              }
              fetchListExternaOtherlJobs={fetchListExternaOtherlJobs}
              listExternalOtherJobs={listExternalOtherJobs}
              openDetailsModal={openDetailsModal}
              setOpenDetailsModal={setOpenDetailsModal}
              selectJobId={selectJobId}
              selectUrl={selectUrl}
              selectJobEmail={selectJobEmail}
              setSelectJobId={setSelectJobId}
              onClickDetails={onClickDetails}
              handleCloseModalExternal={handleCloseModalExternal}
            />
            <>
              {openGamificationLevelOneIncompleteModal && (
                <GamificationLevelOneIncompleteModal
                  universityColor={universityColor}
                  onCloseModal={() => {
                    setOpenGamificationLevelOneIncompleteModal(false);
                  }}
                  handleIsLoadingState={handleIsLoadingState}
                  academic_profile={academic_profile}
                  interests={interests}
                  lifeInterests={life_interests}
                />
              )}
            </>

            {openExternalModal && (
              <ModalInformation
                universityColor={universityColor}
                modalOpenState={openModalExternalJob}
                config={config}
                buttonCancelFunction={handleCloseModalExternalApply}
                buttonConfirmFunction={() =>
                  handleExternalApply(selectJobId, selectUrl, selectJobEmail)
                }
                closeModalFunction={handleCloseModalExternalApply}
                isOtherExternal={true}
              />
            )}
          </>
        </CurriculumContextProvider>
      ) : (
        <ContainerGenericMessage>
          <GenericMessage
            title="Nenhuma vaga encontrada."
            subtitle="Não encontramos nenhuma vaga."
          />
        </ContainerGenericMessage>
      )}
    </>
  );
};

export default ExternalOtherJobs;
