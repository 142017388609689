import React, { useContext, useState, useCallback } from 'react';
import {
  Card,
  CardImg,
  ActivityType,
  StarActivityImg,
  StarActivityWrapper,
  StarActivityImgDiv,
  StarActivityP,
  TitleH4,
  Informations,
  IconImage,
  MoreInfo,
  DashedBorder,
  PlusButton,
  PlusImg,
  Information,
  CardImgDiv,
  EventCardImg,
  Container,
} from '../../Styles/PublishedOpportunities';

import StarActivity from '../../../../../assets/Oportutinites/StarActivity.svg';

import MoreInformationModal from './modal';
import { OportunitiesContext } from '../../Context/OportunitiesContext';
import SkeletonCard from '../SkeletonCard';
import MacroAreas from './modal/MacroAreas';
import { PrincipalCardsObjects } from '../../Resources/PrincipalCardsObjects';
import {
  getAdditionalInfosAttraction,
  getCourses,
  getPeriod,
} from '../../services';

import Tooltip from 'components/NewTooltip';
import { useIcons } from '../../hooks/useIcons';
import { getDetails } from 'services/university/events';
import Loading from '../ExtensionProjects/components/Loading';
import GenericMessage from 'components/GenericMessage';
import ButtonVisibleLearnings from './ButtonVisibleLearnings';

export default function PublishedCard({
  array,
  title,
  setActiveTab,
  learningPublishedByTheGroup = false,
  learningPublishedByTheIEs,
  visionGroup = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [macroModalOpen, setMacroModalOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [loadingMoreInformation, setLoadingMoreInformation] = useState(false);
  const [opportunityId, setOpportunityId] = useState(null);

  const {
    setFormValues,
    formData,
    setModalIsOpen,
    setModalContent,
    setStep,
    setCourse,
    setPeriods,
    educationalGroup,
  } = useContext(OportunitiesContext);

  const {
    WifiIcon,
    UserCircleIcon,
    UserIconDefault,
    GraduationCap,
    ProgramIcon,
    DeletedIcon,
    PublishedIcon,
    IoCloseCircleSharp,
  } = useIcons();

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const returnImageStatus = (item) => {
    if (item.is_published === false) {
      return <DeletedIcon size={15} color="#009291" />;
    }
    if (item.is_published === true && item.status === null) {
      return <PublishedIcon size={15} color="#009291" />;
    }
    if (item.status === 'Expirado(a)') {
      return <IoCloseCircleSharp size={20} color="#009291" />;
    }

    return <PublishedIcon size={17} color="#009291" />;
  };

  const handleClickMoreInfo = async (id) => {
    setOpportunityId(id);
    setLoadingMoreInformation(true);
    const response = await getDetails(id);

    if (response.status === 200) {
      setLoadingMoreInformation(false);
      setModalOpen(true);
      setDetails(response.data);
    }
  };

  const TitlesPrincipal = [
    { id: 'event', title: 'Sobre o Evento' },
    { id: 'course', title: 'Sobre o Curso' },
    { id: 'program', title: 'Sobre o Programa' },
  ];

  const TitlesSecondary = [
    { id: 'article', title: 'Sobre o Artigo' },
    { id: 'e_book', title: 'Sobre o E-book' },
    { id: 'trail', title: 'Sobre a Trilha' },
    { id: 'serie', title: 'Sobre a Série' },
    { id: 'challenge', title: 'Sobre o Desafio' },
    { id: 'announcements', title: 'Sobre o Comunicado' },
  ];
  const handleCallModal = (item) => {
    setStep(1);

    const existPrincipal = TitlesPrincipal.find(
      (option) => option.id === item.type
    );
    const existSecondary = TitlesSecondary.find(
      (option) => option.id === item.type
    );

    if (existPrincipal) {
      PrincipalCardsObjects[0].handleCall(setModalIsOpen, setModalContent);
      return;
    }

    if (existSecondary) {
      PrincipalCardsObjects[1].handleCall(setModalIsOpen, setModalContent);
    }
  };

  const getDataCoursesAndPeriods = useCallback((item) => {
    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }
    const MacroAreas =
      item.target_audiences[0] && item.target_audiences[0].macro_areas;
    const courses =
      item.target_audiences[0] && item.target_audiences[0].courses;
    const semesters =
      item.target_audiences[0] && item.target_audiences[0].periods;

    const SearchCourse = [];
    const SearchPeriods = [];

    if (MacroAreas) {
      MacroAreas.forEach((e) => {
        SearchCourse.push(`macroareas=${e.value}&`);
      });

      const setCourseRequest = async () => {
        const send = SearchCourse.join('');
        const response = await getCourses(send, educationalGroup);
        setCourse(response);
      };

      setCourseRequest();
    }

    if (courses) {
      courses.forEach((e) => {
        SearchPeriods.push(`courses=${e.value.replace('c', '')}&`);
      });
      const sendPeriodoRequest = async () => {
        const send = SearchPeriods.join('');
        const response = await getPeriod(send, educationalGroup);
        setPeriods(getUniqueListBy(response, 'label'));
      };

      sendPeriodoRequest();
    }
  }, []);

  const getAdditionalInfos = async (item) => {
    const response = await getAdditionalInfosAttraction(item);
    if (
      response.image_b64 &&
      response.image_b64.value &&
      response.attraction_document &&
      response.attraction_document.value
    ) {
      const urlImg = `data:application/pdf;base64, ${response.image_b64.value}`;
      const returnImgFromb64 = await fetch(urlImg)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `${response.image_b64.label}`, {
            type: 'image/png',
          });
          return file;
        });

      const urlpdf = `data:application/pdf;base64, ${response.attraction_document.value}`;
      const returnPdf = await fetch(urlpdf)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            `${response.attraction_document.label}`,
            {
              type: 'application/pdf',
            }
          );
          return file;
        });
      setFormValues({
        ...formData,
        ...response,
        attraction_image: returnImgFromb64,
        attraction_document: returnPdf,
        universities:
          response.target_audiences[0] &&
          response.target_audiences[0].universities,
        macro_areas:
          response.target_audiences[0] &&
          response.target_audiences[0].macro_areas,
        courses:
          response.target_audiences[0] && response.target_audiences[0].courses,
        semesters:
          response.target_audiences[0] && response.target_audiences[0].periods,
        competences_specialities: response.competences_specialities,
        setActiveTab: setActiveTab,
        setModalOpen: setModalOpen,
      });
      handleCallModal(response);
      return;
    }

    if (response.image_b64 && response.image_b64.value) {
      const urlImg = `data:application/pdf;base64, ${response.image_b64.value}`;
      const returnImgFromb64 = await fetch(urlImg)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `${response.image_b64.label}`, {
            type: 'image/png',
          });
          return file;
        });
      setFormValues({
        ...formData,
        ...response,
        attraction_image: returnImgFromb64,
        attraction_document: null,
        universities:
          response.target_audiences[0] &&
          response.target_audiences[0].universities,
        macro_areas:
          response.target_audiences[0] &&
          response.target_audiences[0].macro_areas,
        courses:
          response.target_audiences[0] && response.target_audiences[0].courses,
        semesters:
          response.target_audiences[0] && response.target_audiences[0].periods,
        competences_specialities: response.competences_specialities,
        setActiveTab: setActiveTab,
        setModalOpen: setModalOpen,
      });
      handleCallModal(response);
      return;
    }

    if (response.attraction_document && response.attraction_document.value) {
      const urlpdf = `data:application/pdf;base64, ${response.attraction_document.value}`;
      const returnPdf = await fetch(urlpdf)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            `${response.attraction_document.label}`,
            {
              type: 'application/pdf',
            }
          );
          return file;
        });
      setFormValues({
        ...formData,
        ...response,
        attraction_document: returnPdf,
        attraction_image: null,
        universities:
          response.target_audiences[0] &&
          response.target_audiences[0].universities,
        macro_areas:
          response.target_audiences[0] &&
          response.target_audiences[0].macro_areas,
        courses:
          response.target_audiences[0] && response.target_audiences[0].courses,
        semesters:
          response.target_audiences[0] && response.target_audiences[0].periods,
        competences_specialities: response.competences_specialities,
        setActiveTab: setActiveTab,
        setModalOpen: setModalOpen,
      });
      handleCallModal(response);
      return;
    }
    setFormValues({
      ...formData,
      ...response,
      attraction_document: null,
      attraction_image: null,
      universities:
        response.target_audiences[0] &&
        response.target_audiences[0].universities,
      macro_areas:
        response.target_audiences[0] &&
        response.target_audiences[0].macro_areas,
      courses:
        response.target_audiences[0] && response.target_audiences[0].courses,
      semesters:
        response.target_audiences[0] && response.target_audiences[0].periods,
      competences_specialities: response.competences_specialities,
      setActiveTab: setActiveTab,
      setModalOpen: setModalOpen,
    });
    handleCallModal(response);
  };

  const handleClickEdit = async (item, loadingModal, setLoadingModal) => {
    getDataCoursesAndPeriods(item);
    setModalIsOpen(true);
    setLoadingModal(true);
    setFormValues({
      ...item,
      attraction_image: null,
      attraction_document: null,
    });
    await getAdditionalInfos(item.id);
    setLoadingModal(false);
  };

  const returnFirstInformationLabel = (item) => {
    const informationLabel = {
      announcements: item.created_by
        ? item.created_by.user
        : 'Não identificado',
      event:
        item.modality === 'online'
          ? 'Online'
          : item.modality === 'in_person'
          ? 'Presencial'
          : 'Não informado',
      program: item.category ? item.category.label : 'Não informado',
      course: item.course_category
        ? item.course_category.label
        : 'Não informado',
      challenge: item.created_by ? item.created_by.user : 'Não identificado',
      trail: item.created_by ? item.created_by.user : 'Não identificado',
      serie: item.created_by ? item.created_by.user : 'Não identificado',
      article: item.created_by ? item.created_by.user : 'Não identificado',
      e_book: item.created_by ? item.created_by.user : 'Não identificado',
    };
    return informationLabel[item.type];
  };

  const returnFirstInformationImg = (item) => {
    const firstInformationImg = {
      announcements: <UserIconDefault size={15} color="#009291" />,
      event:
        item.modality === 'online' ? (
          <WifiIcon size={16} color="#009291" />
        ) : (
          <UserCircleIcon size={16} color="#009291" />
        ),
      program: <ProgramIcon size={16} color="#009291" />,
      course: <ProgramIcon size={16} color="#009291" />,
      challenge: <UserIconDefault size={15} color="#009291" />,
      trail: <UserIconDefault size={15} color="#009291" />,
      serie: <UserIconDefault size={15} color="#009291" />,
      article: <UserIconDefault size={15} color="#009291" />,
      e_book: <UserIconDefault size={15} color="#009291" />,
    };
    return firstInformationImg[item.type];
  };

  const returnNoPublicateLabel = {
    Comunicados: 'Não identificamos nenhum Comunicado publicado!',
    Eventos: 'Não identificamos nenhum Evento publicado!',
    Cursos: 'Não identificamos nenhum Curso publicado!',
    Programas: 'Não identificamos nenhum Programa publicado!',
    Trilhas: 'Não identificamos nenhuma Trilha publicada!',
    'E-books': 'Não identificamos nenhum E-book publicado!',
    Artigos: 'Não identificamos nenhum Artigo publicado!',
    Desafios: 'Não identificamos nenhum Desafio publicado!',
    Séries: 'Não identificamos nenhuma Série publicada!',
  };

  const returnLabelButton = {
    Comunicados: 'Comunicado',
    Eventos: 'Evento',
    Cursos: 'Curso',
    Programas: 'Programa',
    Trilhas: 'Trilha',
    'E-books': 'E-book',
    Artigos: 'Artigo',
    Desafios: 'Desafio',
    Séries: 'Série',
  };

  const isLogo = (imageURL) => {
    return imageURL && imageURL.includes('logos/') ? true : false;
  };

  const strReduced = (str) => {
    if (str.length > 31) {
      return `${str.substr(0, 31)}...`;
    }
    return str;
  };

  const returnMessageFromPublished = (item) => {
    if (item.published_to_universities_count === 0) {
      return `Publicado em todas as Instituições`;
    }
    if (item.published_to_universities_count === 1) {
      return `Publicado em ${item.published_to_universities_count} Instituição`;
    }
    if (item.published_to_universities_count > 1) {
      return `Publicado em ${item.published_to_universities_count} Instituições`;
    }
  };

  const mapOpportunities = (array) => {
    if (array === null) {
      return <SkeletonCard quantity={randomIntFromInterval(1, 3)} />;
    }

    if (array?.length === 0) {
      return (
        <GenericMessage
          title={returnNoPublicateLabel[title]}
          buttonLabel={`Adicionar ${returnLabelButton[title]}`}
          buttonFunc={() => setActiveTab(0)}
          style={{ marginBottom: '30px' }}
        />
      );
    }

    return array?.map((item) => (
      <Card educationalGroup={learningPublishedByTheGroup}>
        {isLogo(item.attraction_image) ? (
          <CardImgDiv>
            <EventCardImg
              src={item.attraction_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1050';
              }}
            />
          </CardImgDiv>
        ) : (
          <CardImg
            src={
              item.attraction_image ||
              'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1080'
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1080';
            }}
          />
        )}

        {item.validity && (
          <ActivityType>
            <StarActivityWrapper>
              <StarActivityImgDiv>
                <StarActivityImg src={StarActivity} />
              </StarActivityImgDiv>{' '}
              <StarActivityP>{item.validity.label}</StarActivityP>
            </StarActivityWrapper>
          </ActivityType>
        )}
        <TitleH4 title={item.name}>{item.name}</TitleH4>

        <Informations>
          <Information>
            <IconImage>{returnFirstInformationImg(item)}</IconImage>
            <p> {returnFirstInformationLabel(item)} </p>
          </Information>

          {learningPublishedByTheGroup === false && (
            <Information>
              <IconImage>
                <GraduationCap size={22} color="#009291" />
              </IconImage>
              {item.created_by && item.created_by.name.length <= 20 ? (
                <p>{item.created_by.name}</p>
              ) : (
                <Tooltip text={item.created_by.name}>
                  <p>{strReduced(item.created_by.name)}</p>
                </Tooltip>
              )}
            </Information>
          )}

          <Information>
            <IconImage>{returnImageStatus(item)}</IconImage>
            {learningPublishedByTheGroup === true &&
            item.status === 'Publicado(a)' ? (
              <p>{returnMessageFromPublished(item)}</p>
            ) : (
              <p>{item.status}</p>
            )}
          </Information>

          <MoreInfo educationalGroup={learningPublishedByTheGroup}>
            {item.created_by.user === 'Workalove' && (
              <ButtonVisibleLearnings learningID={item.id} learnings={item} />
            )}
            <button onClick={() => handleClickMoreInfo(item.id)}>
              {opportunityId === item.id && loadingMoreInformation === true ? (
                <Loading color={'#FFF'} maxHeight="35px" />
              ) : (
                'Mais informações'
              )}
            </button>
          </MoreInfo>
        </Informations>
      </Card>
    ));
  };

  return (
    <Container content={array?.length !== 0}>
      {Object.keys(details).length > 0 ? (
        <MoreInformationModal
          item={details}
          setModalOpen={setModalOpen}
          isOpen={modalOpen}
          setItem={setDetails}
          setMacroModalOpen={setMacroModalOpen}
          handleClickEdit={handleClickEdit}
          setActiveTab={setActiveTab}
          learningPublishedByTheGroup={learningPublishedByTheGroup}
          visionGroup={visionGroup}
          learningPublishedByTheIEs={learningPublishedByTheIEs}
        />
      ) : null}

      {Object.keys(details).length > 0 ? (
        <MoreInformationModal
          isOpen={macroModalOpen}
          setModalOpen={setMacroModalOpen}
          setMacroModalOpen={setMacroModalOpen}
          item={details}
          children={
            <MacroAreas item={details} visionGroup={educationalGroup} />
          }
        />
      ) : null}
      {mapOpportunities(array)}
    </Container>
  );
}
