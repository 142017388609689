import React, { useState, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import {
  Content,
  SelectBox,
  SimpleButton,
  ContainerForms,
  ContainerButton,
  ParagraphFeedback,
  ButtonNextStep,
  IconImg,
  Phrase,
} from './styled';
import iconPrevs from 'assets/img/iconsCurriculum/iconPrevs.svg';
import iconNext from 'assets/img/iconsCurriculum/iconNext.svg';
import NoteInput from 'components/UnformFields/noteInput';
import { finalizationVacancyAdapter } from '../utils/finalizationVacancyAdapter';
import {
  finishHistoryJob,
  finishJob,
} from 'views/StaffPages/jobOpeningsRebuild/services'; //ies
import {
  finishJobHistory as finishJobHistoryCompany,
  finishJob as finishJobCompany,
} from 'views/JobOfferRebuild/services'; //company
import SimpleReactSelect from 'components/UnformFields/SimpleReactSelect';
import {
  customCommonStyles,
  selectTheme,
} from 'components/ListCandidates/Styles/BaseComponent';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import * as Yup from 'yup';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import {
  loadJobsProgressCompany,
  reloadProgressJobs,
} from '../utils/handleReloadJobs';

export const ModalStepsFinalizeJob = (props) => {
  const {
    jobID,
    status,
    closeModal,
    university,
    company,
    setModalFinish,
    optionsCandidate,
    activeTab,
    setListJobs,
    setJobsInstitution,
    setListJobsCompany,
    setListJobsCompanyHistory,
    setJobsCount,
    companyFilters,
    universityFilters,
    dataFunction,

    selectedStatus,
    setSelectedStatus,
    selectedCandidate,
    setSelectedCandidate,
    givenFeedback,
    setGivenFeedback,
    inputCandidateVisible,
    setInputCandidateVisible,
    isStepOne,
    setIsStepOne,
  } = props;

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [formData, setFormData] = useState(null);
  const [stepOneData, setStepOneData] = useState({});

  const [systemJob, setSystemJob] = useState([]);
  const [externalJob, setExternalJob] = useState(false);

  const stepOneRef = useRef(null);
  const stepTwoRef = useRef(null);

  const schema = Yup.object().shape({
    feedback: Yup.string().required('Campo obrigatório'),
  });

  const options = [
    {
      id: 1,
      value: 'withoutCandidates',
      label: 'A vaga não foi preenchida por um candidato do sistema',
    },
    {
      id: 2,
      value: 'noContract',
      label: 'A vaga foi finalizada sem contratação',
    },
    {
      id: 3,
      value: 'candidates',
      label: 'A vaga foi preenchida por um candidato do sistema',
    },
  ];

  const statusSelectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

  const loadJobsData = async (params = '') => {
    setListJobs(null);
    dataFunction.handlePageChange('limit=0', params);
  };

  const loadHistoryJobsData = async (params) => {
    setJobsInstitution(null);
    dataFunction.handlePageChangeInstitution('limit=0', params);
  };

  const handleCandidateSelect = (option) => {
    const { value: selectedValue } = option;

    if (selectedValue) {
      if (selectedCandidate) {
        setSystemJob((prevCandidates) => [
          ...prevCandidates,
          selectedCandidate,
        ]);
      }
      setIsButtonEnabled(true);
    }
    setSelectedCandidate(option);
  };

  const handleProcessFinalized = (selectedOption) => {
    if (!selectedOption) {
      return;
    }
    const { id: selectedId } = selectedOption;

    if (selectedId === 1 || selectedId === 2) {
      setExternalJob(true);
      setSelectedStatus(selectedOption);
      setInputCandidateVisible(false);
      setIsButtonEnabled(true);
    }

    if (selectedId === 3) {
      if (selectedCandidate) {
        setSystemJob((prevCandidates) => [
          ...prevCandidates,
          selectedCandidate,
        ]);
      }
      setSelectedStatus(selectedOption);
      setInputCandidateVisible(true);
      setIsButtonEnabled(true);
    }

    setFormData(selectedId);
  };

  const handleFeedbackInput = (event) => {
    if (!event) {
      return;
    }

    setGivenFeedback(event.target.value);
  };

  useEffect(() => {
    if (selectedStatus || systemJob || externalJob) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [selectedStatus, systemJob, externalJob]);

  const onClickBack = () => {
    setIsStepOne(true);
  };

  const handleStepTwo = (data) => {
    const candidateObject = selectedCandidate;
    const updatedData = typeof data === 'object' ? { ...data } : {};
    updatedData.candidates = [candidateObject];
    setStepOneData(updatedData);
    setIsStepOne(false);
  };

  const handleSubmit = async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const finalData = { ...data, ...stepOneData };
      setFormData(finalData);

      const has_contracting = selectedStatus.id === 3;
      const candidates =
        stepOneData.candidates?.filter((candidate) => candidate !== null).map((candidate) => candidate.value) || [];

      const dataAdapter = finalizationVacancyAdapter({
        feedback: data.feedback,
        candidates,
        statusSelect: stepOneData.statusSelect || [],
        has_contracting,
        selected_option_id: selectedStatus.id,
      });

      if (university) {
        const queryParams = queryParamsFromFilterObject(universityFilters);

        if (status) {
          await finishHistoryJob(jobID, dataAdapter);
          loadHistoryJobsData(queryParams);
        } else {
          await finishJob(jobID, dataAdapter);
          loadJobsData(queryParams);
        }
      }
      if (company) {
        const queryParams = queryParamsFromFilterObject(companyFilters);

        if (status) {
          await finishJobHistoryCompany(jobID, dataAdapter);
          loadJobsProgressCompany(
            setListJobsCompanyHistory,
            dataFunction,
            queryParams
          );
        } else {
          await finishJobCompany(jobID, dataAdapter);
          reloadProgressJobs(setListJobsCompany, dataFunction, queryParams);
        }
      }
      customSnackbar('Vaga finalizada com sucesso!', 'confirmation');
      setModalFinish(true);
      closeModal(true);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        stepTwoRef.current.setErrors(validationErrors);
      }
      customSnackbar(
        'Não foi possível finalizar a vaga. Tente novamente mais tarde!',
        'error'
      );
    }
  };

  return (
    <Content>
      {isStepOne ? (
        <ContainerForms>
          <Form
            ref={stepOneRef}
            initialData={formData}
            onSubmit={handleStepTwo}
          >
            <SelectBox>
              <Phrase>
                Esperamos que tenha tido êxito em seu processo seletivo!
              </Phrase>
              <SimpleReactSelect
                name="statusSelect"
                label="Para concluir o processo de finalização da vaga, poderia nos informar sobre o status atual dela?"
                placeholder="Selecione"
                options={
                  optionsCandidate.length >= 1
                    ? options
                    : options.filter((option) => option.value !== 'candidates')
                }
                onChange={handleProcessFinalized}
                theme={selectTheme}
                styles={statusSelectStyles}
                defaultValue={selectedStatus}
                value={selectedStatus}
              />
            </SelectBox>

            {inputCandidateVisible && (
              <SimpleReactSelect
                name="candidates"
                label="Informe o nome do candidato"
                placeholder="Selecione"
                options={optionsCandidate}
                onChange={handleCandidateSelect}
                theme={selectTheme}
                styles={customCommonStyles}
                defaultValue={selectedCandidate}
                value={selectedCandidate}
              />
            )}

            <ContainerButton>
              <ButtonNextStep type="submit" disabled={!isButtonEnabled}>
                <IconImg src={iconNext} />
              </ButtonNextStep>
            </ContainerButton>
          </Form>
        </ContainerForms>
      ) : (
        <ContainerForms>
          <Form ref={stepTwoRef} initialData={formData} onSubmit={handleSubmit}>
            <SelectBox>
              <NoteInput
                name="feedback"
                label={
                  <ParagraphFeedback>
                    Envie um feedback para os candidatos que{' '}
                    <strong>não foram aprovados</strong> no processo seletivo
                  </ParagraphFeedback>
                }
                placeholder="Digite aqui"
                defaultValue={givenFeedback}
                onChange={handleFeedbackInput}
              />
            </SelectBox>

            <ContainerButton isStepTwo>
              <ButtonNextStep type="button" onClick={onClickBack}>
                <IconImg src={iconPrevs} />
              </ButtonNextStep>

              <SimpleButton type="submit">Finalizar vaga</SimpleButton>
            </ContainerButton>
          </Form>
        </ContainerForms>
      )}
    </Content>
  );
};
